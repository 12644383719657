<template>
    <BlockContainer
        :id="'product-resource-gallery-' + slugify(data.title)"
        :background-color="data.backgroundColor"
        :bottom-angle="data.bottomAngle"
        :angle-background-color="data.nextBackgroundColor"
    >
        <div class="gap-x-8 py-8 md:py-20 lg:flex">
            <div class="relative mb-12 md:mb-0 lg:w-1/4">
                <div class="lg:top-32">
                    <div class="flex flex-col">
                        <div class="mb-4 flex justify-between">
                            <div>
                                FILTER BY ({{
                                    filteredProductResourceItems.length
                                }}
                                RESULTS)
                            </div>
                            <a class="cursor-pointer" @click="resetFilters">
                                Reset
                            </a>
                        </div>
                        <ProductFilterRefinementListContent
                            v-if="data.productTypeFilters?.length > 0"
                            title="Product Type"
                            type="Product Resource Gallery"
                            :expanded-default="false"
                            :mobile-menu-open="false"
                            :items="productTypeFilterItems"
                            @toggle="toggleProductTypeFilter"
                        />
                        <ProductFilterRefinementListContent
                            v-if="data.productCategoryFilters?.length > 0"
                            title="Product Categories"
                            type="Product Resource Gallery"
                            :expanded-default="false"
                            :mobile-menu-open="false"
                            :items="productCategoryFilterItems"
                            @toggle="toggleProductCategoryFilter"
                        />
                        <ProductFilterRefinementListContent
                            v-if="data.propertyFilters?.length > 0"
                            title="Eco-Friendly"
                            type="Product Resource Gallery"
                            :expanded-default="false"
                            :mobile-menu-open="false"
                            :items="propertyFilterItems"
                            @toggle="togglePropertyFilter"
                        />
                        <ProductFilterRefinementListContent
                            v-if="data.materialFilters?.length > 0"
                            title="Materials"
                            type="Product Resource Gallery"
                            :expanded-default="false"
                            :mobile-menu-open="false"
                            :items="materialFilterItems"
                            @toggle="toggleMaterialFilter"
                        />
                        <ProductFilterRefinementListContent
                            v-if="data.specialEffectFilters?.length > 0"
                            title="Special Effects"
                            type="Product Resource Gallery"
                            :expanded-default="false"
                            :mobile-menu-open="false"
                            :items="specialEffectFilterItems"
                            @toggle="toggleSpecialEffectFilter"
                        />
                        <ProductFilterRefinementListContent
                            v-if="data.featureAndAccessoryFilters?.length > 0"
                            title="Features & Accessories"
                            type="Product Resource Gallery"
                            :expanded-default="false"
                            :mobile-menu-open="false"
                            :items="featureAndAccessoryFilterItems"
                            @toggle="toggleFeatureAndAccessoryFilter"
                        />
                        <ProductFilterCta
                            class="mt-8 hidden md:block"
                            link-url="/free-sample-pack"
                            link-label="Send Me Stuff"
                            text="Get your hands on our packaging before you place your order. It just makes sense."
                            heading="Free Samples"
                        />
                    </div>
                </div>
            </div>
            <div class="lg:w-3/4">
                <h2 class="mb-8">{{ data.title }}</h2>
                <div
                    class="grid grid-cols-1 gap-5 md:grid-cols-3 md:px-0 lg:grid-cols-3"
                >
                    <div
                        v-for="item in filteredProductResourceItems"
                        :key="'item-' + item.title"
                        class="relative flex flex-col justify-start rounded-lg border border-gray-100 bg-white pb-5"
                    >
                        <StoryblokImage
                            v-if="item.image"
                            :src="item.image.src"
                            :alt="item.image.alt"
                            class="relative rounded-t-lg"
                            sizes="324px md:352px"
                        />
                        <TagGroup
                            v-if="item.tag && item.tag != ''"
                            :tags="[item.tag]"
                            class="flex-wrap pl-5 pt-5"
                        />
                        <h3 class="pl-5 pr-5 pt-5">{{ item.title }}</h3>
                        <div class="pl-5 pr-5 pt-3">{{ item.description }}</div>
                        <div v-if="item.link" class="pl-5 pr-5 pt-3">
                            <a
                                :href="item.link.url"
                                class="font-bold no-underline"
                            >
                                {{ item.link.label }}
                            </a>
                        </div>
                        <img
                            v-if="item.icon?.src"
                            :src="item.icon.src"
                            :alt="item.icon.alt"
                            class="absolute left-[10px] top-[10px] h-[60px] w-[60px]"
                        />
                    </div>
                </div>
            </div>
        </div>
    </BlockContainer>
</template>

<script setup lang="ts">
import ProductResourceGalleryType from '~/types/ProductResourceGalleryType';
import ProductResourceItemType from '~/types/ProductResourceItemType';
import BlockContainer from '~/components/BlockContainer.vue';
import ProductFilterRefinementListContent from '~/components/page-building/product-filter/components/ProductFilterRefinementListContent.vue';
import ProductFilterCta from '~/components/page-building/product-filter/components/ProductFilterCta.vue';

const props = defineProps<{
    data: ProductResourceGalleryType;
}>();

const productTypeFilters = ref<any>([]);
const productCategoryFilters = ref<any>([]);
const propertyFilters = ref<any>([]);
const materialFilters = ref<any>([]);
const specialEffectFilters = ref<any>([]);
const featureAndAccessoryFilters = ref<any>([]);

const productTypeFilterItems = ref<any>([]);
const productCategoryFilterItems = ref<any>([]);
const propertyFilterItems = ref<any>([]);
const materialFilterItems = ref<any>([]);
const specialEffectFilterItems = ref<any>([]);
const featureAndAccessoryFilterItems = ref<any>([]);

const filteredProductResourceItems = ref<ProductResourceItemType[]>(
    props.data.resourceItems,
);
const selectedFilterCount = ref<any>(0);

onMounted(() => {
    resetFilters();
});

const resetFilters = () => {
    productTypeFilterItems.value = [];
    props.data.productTypeFilters.forEach((filter: string) => {
        productTypeFilters.value[filter] = false;
        productTypeFilterItems.value.push({
            value: filter,
            isRefined: false,
        });
    });
    productCategoryFilterItems.value = [];
    props.data.productCategoryFilters.forEach((filter: string) => {
        productCategoryFilters.value[filter] = false;
        productCategoryFilterItems.value.push({
            value: filter,
            isRefined: false,
        });
    });
    propertyFilterItems.value = [];
    props.data.propertyFilters.forEach((filter: string) => {
        propertyFilters.value[filter] = false;
        propertyFilterItems.value.push({
            value: filter,
            isRefined: false,
        });
    });
    materialFilterItems.value = [];
    props.data.materialFilters.forEach((filter: string) => {
        materialFilters.value[filter] = false;
        materialFilterItems.value.push({
            value: filter,
            isRefined: false,
        });
    });
    specialEffectFilterItems.value = [];
    props.data.specialEffectFilters.forEach((filter: string) => {
        specialEffectFilters.value[filter] = false;
        specialEffectFilterItems.value.push({
            value: filter,
            isRefined: false,
        });
    });
    featureAndAccessoryFilterItems.value = [];
    props.data.featureAndAccessoryFilters.forEach((filter: string) => {
        featureAndAccessoryFilters.value[filter] = false;
        featureAndAccessoryFilterItems.value.push({
            value: filter,
            isRefined: false,
        });
    });
    selectedFilterCount.value = 0;

    nextTick(() => {
        filterProductResourceItems();
    });
};

const filterProductResourceItems = () => {
    filteredProductResourceItems.value = [];
    props.data.resourceItems.forEach((item: ProductResourceItemType) => {
        const filteredOut =
            processFilter(
                item.productType,
                props.data.productTypeFilters,
                productTypeFilters.value,
            ) ||
            processFilter(
                item.productCategory,
                props.data.productCategoryFilters,
                productCategoryFilters.value,
            ) ||
            processFilter(
                item.property,
                props.data.propertyFilters,
                propertyFilters.value,
            ) ||
            processFilter(
                item.material,
                props.data.materialFilters,
                materialFilters.value,
            ) ||
            processFilter(
                item.specialEffect,
                props.data.specialEffectFilters,
                specialEffectFilters.value,
            ) ||
            processFilter(
                item.featureOrAccessory,
                props.data.featureAndAccessoryFilters,
                featureAndAccessoryFilters.value,
            );

        if (!filteredOut) {
            filteredProductResourceItems.value.push(item);
        }
    });
};

const processFilter = (
    itemFilterValue: string[],
    filters: string[],
    filterValues: any,
) => {
    if (!includesActiveFilter(filters, filterValues)) {
        return false;
    }

    let filterHit = false;
    filters.forEach((filter: string) => {
        if (itemFilterValue.includes(filter) && filterValues[filter]) {
            filterHit = true;
        }
    });

    return !filterHit;
};

const includesActiveFilter = (filterOptions: string[], filterValues: any) => {
    let active = false;
    filterOptions.forEach((filter: string) => {
        if (filterValues[filter]) {
            active = true;
        }
    });
    return active;
};

const toggleProductTypeFilter = (filter: string) => {
    productTypeFilters.value[filter] = !productTypeFilters.value[filter];
    selectedFilterCount.value += productTypeFilters.value[filter] ? 1 : -1;
    toggleFilterItem(productTypeFilterItems, filter);
    nextTick(() => {
        filterProductResourceItems();
    });
};

const toggleProductCategoryFilter = (filter: string) => {
    productCategoryFilters.value[filter] =
        !productCategoryFilters.value[filter];
    selectedFilterCount.value += productCategoryFilters.value[filter] ? 1 : -1;
    toggleFilterItem(productCategoryFilterItems, filter);
    nextTick(() => {
        filterProductResourceItems();
    });
};

const togglePropertyFilter = (filter: string) => {
    propertyFilters.value[filter] = !propertyFilters.value[filter];
    selectedFilterCount.value += propertyFilters.value[filter] ? 1 : -1;
    toggleFilterItem(propertyFilterItems, filter);
    nextTick(() => {
        filterProductResourceItems();
    });
};

const toggleMaterialFilter = (filter: string) => {
    materialFilters.value[filter] = !materialFilters.value[filter];
    selectedFilterCount.value += materialFilters.value[filter] ? 1 : -1;
    toggleFilterItem(materialFilterItems, filter);
    nextTick(() => {
        filterProductResourceItems();
    });
};

const toggleSpecialEffectFilter = (filter: string) => {
    specialEffectFilters.value[filter] = !specialEffectFilters.value[filter];
    selectedFilterCount.value += specialEffectFilters.value[filter] ? 1 : -1;
    toggleFilterItem(specialEffectFilterItems, filter);
    nextTick(() => {
        filterProductResourceItems();
    });
};

const toggleFeatureAndAccessoryFilter = (filter: string) => {
    featureAndAccessoryFilters.value[filter] =
        !featureAndAccessoryFilters.value[filter];
    selectedFilterCount.value += featureAndAccessoryFilters.value[filter]
        ? 1
        : -1;
    toggleFilterItem(featureAndAccessoryFilterItems, filter);
    nextTick(() => {
        filterProductResourceItems();
    });
};

const toggleFilterItem = (items: any, filter: string) => {
    for (let i = 0; i < items.value.length; i++) {
        if (items.value[i].value == filter) {
            items.value[i].isRefined = !items.value[i].isRefined;
        }
    }
};
</script>
